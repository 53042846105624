<template>
    <div class="topView">
        <div class="topView_main ">
            <el-dropdown>
                <span class="iconFont icon-qiehuanyuyan active1"></span>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item v-for='(item,index) in languagesObj_' :disabled='getLang_ === index'
                            :key='item' @click="changeLang(index)">{{item}}</el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
            <el-dropdown>
                <span class="topView_main_next write-main-tit active1">
                    <i class="icon-gerentouxiang_o iconFont"></i>
                    <span>{{useName}}</span>
                    <i class="icon-xiangxia iconFont we_"></i>
                </span>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item @click="jumpHome">{{$t("topText.text2")}}</el-dropdown-item>
                        <el-dropdown-item @click="openSetinfo">{{$t("topText.text3")}}</el-dropdown-item>
                        <el-dropdown-item divided @click="exitFun">{{$t("topText.text4")}}</el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </div>
        <slot />
        <el-dialog v-model="dialogFormVisible" title="修改个人信息" width="600px" >
            <el-form :model="formValue" :inline="true" label-width="80px" :rules="rules" ref="ruleFormRef">
                <el-form-item label="用户名" prop="username">
                    <el-input v-model="formValue.username" disabled/>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <el-input v-model="formValue.phone" />
                </el-form-item>
                <el-form-item label="昵称" prop="nickname">
                    <el-input v-model="formValue.nickname" />
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                    <el-input v-model="formValue.email" />
                </el-form-item>
                <el-form-item label="原密码" prop="password">
                    <el-input v-model="formValue.password" type="password"/>
                </el-form-item>
                <el-form-item label="新密码" prop="newpassword1">
                    <el-input v-model="formValue.newpassword1"  type="password"/>
                </el-form-item>
                <el-form-item label="确认密码" prop="newpassword2">
                    <el-input v-model="formValue.newpassword2"  type="password"/>
                </el-form-item>
            </el-form>
            <template #footer>
            <span class="dialog-footer">
                <el-button @click="dialogFormVisible = false">关闭</el-button>
                <el-button type="primary" @click="submitFun(ruleFormRef)">提交</el-button>
            </span>
            </template>
        </el-dialog>
    </div>
</template>
<script setup>
    import { languagesObj } from '@/config/language.js'
    import { ref,reactive } from 'vue'
    import { bindLoginInfo } from '@/commonMethod/index'
    import { ElMessageBox,ElMessage } from 'element-plus'
    import api_ from "@/api/index"
    import { useI18n } from 'vue-i18n'
    import { changeLanguageFun } from '@/commonMethod/index.js'
    import { userStore } from '@/store/user'
    import { configStore } from '@/store/config.js'
    import { inject } from 'vue';
    import website from '@/config/website'
    import { useRouter } from 'vue-router'
    import bindTags from '@/router/tags'
    
    const router = useRouter()

    const { locale } = useI18n()
    const useName = ref(null);
    const getLang_ = ref('');
    getLang_.value = configStore().getLange
    useName.value = userStore().getUserInfo[0].username
    const toSwitch = inject('toSwitch');
    const dialogFormVisible = ref(false)
    const formValue = reactive({
        username:'',phone:'',nickname:'',email:'',
        password:'',newpassword1:'',newpassword2:''
    })
    const ruleFormRef = ref(null)
    const validatePass2 = (rule, value, callback) => {
        if (value !== formValue.newpassword1) {
            callback(new Error("两次密码不一致"))
        } else {
            callback()
        }
    }
    const rules = reactive({
        username: [
            { required: true, message: '请输入', trigger: 'blur' },
        ],
        phone: [
            { required: true, message: '请输入', trigger: 'blur' },
        ],
        nickname: [
            { required: true, message: '请输入', trigger: 'blur' },
        ],
        email: [
            { required: true, message: '请输入', trigger: 'blur' },
        ],
        password: [
            { required: true, message: '请输入', trigger: 'blur' },
        ],
        newpassword1: [
            { required: true, message: '请输入', trigger: 'blur' },
        ],
        newpassword2: [
            { required: true, message: '请输入', trigger: 'blur' },
            { validator: validatePass2, trigger: 'blur' },
        ],
    })
    const { username,phone,nickname,email } = JSON.parse(sessionStorage.getItem('userInfo'))[0]
    formValue.username = username;
    formValue.phone = phone;
    formValue.nickname = nickname;
    formValue.email = email;
    const languagesObj_ = ref(languagesObj)
    const changeLang = (e)=>{
        getLang_.value = e;
        changeLanguageFun(e,locale);
        sessionStorage.setItem('tagsArray-to-update',true);
        toSwitch()
    }
    const jumpHome = ()=>{
        bindTags({id:1,name:'首页',path:'/homePage'})
        router.push({ path: '/main/homePage'})
    }
    const submitFun = (formEl)=>{
        if(!formEl) return 
        formEl.validate((valid) => {
            if (valid) {
                api_.updateUser1(formValue).then(res=>{
                    if(res.code === 0){
                        ElMessage.success('退出成功')
                        bindLoginInfo('');
                        let tenantId = sessionStorage.getItem(website.key+'-tenantId') || 1;
                        sessionStorage.clear();
                        sessionStorage.setItem(website.key+'-tenantId',tenantId)
                        location.reload()
                    }else{
                        ElMessage.error(res.msg)
                    }
                })
            }
        })
    }
    const exitFun = ()=>{
        ElMessageBox.confirm('是否继续退出?','温馨提示',{
            confirmButtonText: '继续',
            cancelButtonText: '关闭',
            type: 'warning',
        }).then(() => {
            api_.logoutByUsername().then(res=>{
                if(res.code === 0){
                    ElMessage.success('退出成功')
                    bindLoginInfo('');
                    let tenantId = sessionStorage.getItem(website.key+'-tenantId') || 1;
                    sessionStorage.clear();
                    sessionStorage.setItem(website.key+'-tenantId',tenantId)
                    location.reload()
                }else{
                    ElMessage.warning(res.msg)
                }
            })
        })
    }
    const openSetinfo = ()=>{//修改个人信息
        dialogFormVisible.value = true
    }
</script>


<style lang="scss" scoped>
    @import "@/assets/scss/parts.scss"; //变量样式
    @import "@/assets/scss/baseStyle/index.scss";
    .topView{
        flex: 1;
        height: 60px;
        background-color: $background-main-color;
        // padding-right: 20px;
        width: calc(100vw - 210px);
        &_main{
            @include display_(centerA);
            justify-content: flex-end;
            height: 100%;
            padding-right: 20px;
            &_next{
                @include display_(centerA);
                height: 100%;
                margin-left: 20px;
            }
            .iconFont{
                font-size: 14px;
                color: #fff;
                margin-left: 10px;
            }
            .we_{
                font-size: 12px !important;
            }
            .icon-gerentouxiang_o{
                margin-left: 5px;
                font-size: 20px;
            }
            .icon-qiehuanyuyan{
                font-size: 20px
            }
        }
        &_mains{
            padding-left: 10px;
        }
    }
</style>